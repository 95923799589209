import fnLazyLoad from "./scripts/lazyLoad";
import fnInitCarousels from "./scripts/carousels";
import fnAnimateOnScroll from "./scripts/aos";
import fnNavbarDropdown from "./scripts/navbar";

const init = () => {
   fnLazyLoad();
   fnInitCarousels();
   fnAnimateOnScroll();
   fnNavbarDropdown();
};

addEventListener("page:loaded", init);
