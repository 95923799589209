import Swiper, { Autoplay, EffectFade, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

const bannerCarousel = () => {
   new Swiper(".swiper-container", {
      modules: [EffectFade, Pagination, Autoplay],
      spaceBetween: 30,
      effect: "fade",
      speed: 800,
      allowTouchMove: true,
      loop: true,
      autoplay: {
         delay: 6000,
         disableOnInteraction: true
      },
      fadeEffect: {
         crossFade: true
      },
      pagination: {
         el: ".swiper-pagination",
         clickable: true
      },
      breakpoints: {
         1024: {
            loop: false,
            allowTouchMove: false
         }
      }
   });
};

export default function fnInitCarousels() {
   bannerCarousel();
}
