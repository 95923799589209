export default function fnNavbarDropdown() {

   if (window.innerWidth < 1025) {
      const dropdownToggle = document.querySelector(".dropdown .dropdown-toggle");
      const dropdownMenu = document.querySelector(".dropdown .dropdown-menu");


      dropdownToggle.addEventListener("click", () => {
         dropdownMenu.classList.toggle("show");
      });

      if (dropdownMenu) {
         const dropdownItems = dropdownMenu.querySelectorAll(".dropdown-menu .dropdown-item");
         dropdownItems.forEach(option => {
            option.addEventListener("click", () => {
               dropdownMenu.classList.remove("show");
            });
         });
      }


   }
}
